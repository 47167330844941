.SubHero {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubHero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubHero-left {
  width: 55%;
  height: 19rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.SubHero-left-header {
  margin-top: -2rem;
  display: flex;
  align-items: center;
}

.SubHero-left-icon {
  width: 105px;
  margin-left: -0.75rem;
}

.SubHero-left-title {
  margin-left: 1rem;
}

.SubHero-left-img {
  width: 129px;
  height: 129px;
}

.SubHero-left-text {
  margin-top: -2rem;
  margin-right: 2rem;
}

.SubHero-right {
  width: 45%;
  height: 19rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px;
  justify-items: end;
}

@media screen and (max-width: 1100px) {
  .SubHero {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .SubHero-left {
    width: 100%;
    align-items: center;
  }

  .SubHero-left-text {
    text-align: justify;
    padding: 0 2.4rem;
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {
  .SubHero-left-header .small {
    margin-bottom: 2rem;
  }
}
