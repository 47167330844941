.StoryCardMobile {
  position: relative;
}

.StoryCardMobile:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.StoryCardMobile-heading-container {
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
}
