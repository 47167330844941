@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Allura&family=Cinzel+Decorative:wght@700&family=Cinzel:wght@400;700&family=Montserrat:wght@300;400&family=Rajdhani:wght@700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-dark-gold: #deb16c;
  --color-light-gold: #eccf81;
  --color-dark-silver: #606167;
  --color-light-silver: #c6c9d0;
  --container-width: 1100px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.page-link {
  text-decoration: underline;
}

.text-white {
  color: var(--color-white);
}

.text-light-gold {
  color: var(--color-light-gold);
}

.text-medium-gold {
  color: #ffd771;
}

.text-dark-gold {
  color: var(--color-dark-gold);
}

.text-light-silver {
  color: var(--color-light-silver);
}

.text-dark-silver {
  color: var(--color-dark-silver);
}

.rajdhani-bold-12 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 12px;
  line-height: 2.75rem;
  letter-spacing: 1px;
}

.rajdhani-bold-18 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.125rem;
  line-height: 2.75rem;
  letter-spacing: 1px;
}

.rajdhani-bold-24 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.allura-regular-28 {
  font-family: 'Allura', cursive;
  font-size: 28px;
}

.allura-regular-36 {
  font-family: 'Allura', cursive;
  font-size: 2.25rem;
}

.alfa-slab-one-10 {
  font-family: 'Alfa Slab One', cursive;
  font-size: 10px;
  letter-spacing: 3px;
}

.alfa-slab-one-18 {
  font-family: 'Alfa Slab One', cursive;
  font-size: 18px;
  letter-spacing: 3.5px;
}

.cinzel-regular-24 {
  font-family: 'Cinzel', serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-30 {
  font-family: 'Cinzel', serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-36 {
  font-family: 'Cinzel', serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-48 {
  font-family: 'Cinzel', serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 64px;
}

.cinzel-regular-56 {
  font-family: 'Cinzel', serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
}

.cinzel-bold-36 {
  font-family: 'Cinzel', serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 64px;
}

.cinzel-bold-56 {
  font-family: 'Cinzel', serif;
  font-size: 56px;
  font-weight: bold;
  line-height: 64px;
}

.cinzel-decorative-bold-18 {
  font-family: 'Cinzel Decorative', cursive;
  font-size: 18px;
  font-weight: bold;
}

.montserrat-light-12 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.montserrat-light-14 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
}

.montserrat-regular-14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 28px;
}

.nav-link {
  color: var(--color-white);
  text-decoration: none;
}

.container-1100 {
  width: var(--container-width);
}

.pt-84 {
  padding-top: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

.ptb-84 {
  padding: 84px 0;
}

.ptb-168 {
  padding: 168px 0;
}

.ml-16 {
  margin-left: 16px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mtb-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.text-center {
  text-align: center;
}
