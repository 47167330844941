.StoryCard {
  width: 50%;
  /* background: var(--color-light-gold); */
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryCard:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.StoryCard-container {
  padding: 8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .StoryCard-container {
    padding: 2rem;
  }
}

@media screen and (max-width: 1100px) {
  .StoryCard-container {
    padding: 2rem;
  }
}
