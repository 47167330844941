.Blog {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Blog-header {
  margin-top: 160px;
}

.Blog-body {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4rem;
}

.Blog-card {
  width: 400px;
  height: 432px;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.Blog-image {
  position: relative;
  border-radius: 15px 15px 0 0;
}

.Blog-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-top: 8px solid var(--color-dark-gold);
  border-radius: 15px 15px 0 0;
}

.Blog-content div:first-child {
  height: 80px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
}

.Blog-card-container span h3 {
  margin: 2rem;
  color: black;
  text-align: center;
}

.Blog-content div {
  margin: 2rem;
  margin-top: 0;
  color: var(--color-dark-silver);
}

.Blog-link {
  margin-right: 3rem;
  text-align: right;
  color: var(--color-dark-gold);
  text-decoration: underline;
  font-size: 0.75rem;
}

@media screen and (max-width: 1439px) {
  .Blog-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .Blog {
    padding: 0 2rem;
  }

  .Blog-header {
    margin-top: 5rem;
  }

  .Blog-card {
    width: 90vw;
    height: 432px;
    background: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }

  .Blog-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
