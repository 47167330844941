.Attributions {
  background: var(--color-light-gold);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Attributions .Attributions-container img {
  width: 50px;
}
