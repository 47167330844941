.BottomHero {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BottomHero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .BottomHero {
    padding: 4rem 0;
  }

  .BottomHero-container p {
    text-align: left;
    line-height: 43px;
    margin: 0 2.4rem;
  }
}

@media screen and (max-width: 1100px) {
  .BottomHero {
    padding: 4rem 1rem;
  }
}
