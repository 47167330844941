.Book {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-silver);
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.Book-container {
  width: 905px;
  height: 75%;
  margin-top: 12rem;
}

.calendly-inline-widget {
  height: 700px !important;
  margin-top: 2rem !important;
}

.price-container {
  width: 680px;
  background: white;
  border-radius: 5px;
  padding: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.price-container hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.price-table-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  /* .Book {
    background: white;
  } */

  .Book-container {
    width: 50%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); */
    margin-top: 5rem;
  }

  .calendly-inline-widget {
    height: 500px !important;
    margin-bottom: 2rem !important;
  }

  .price-container {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  /* .Book {
    background: white;
  } */

  .Book-container {
    width: 90%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); */
  }

  .calendly-inline-widget {
    height: 500px !important;
    margin-bottom: 2rem !important;
  }

  .price-container {
    width: 100%;
  }
}
