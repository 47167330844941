@import url(https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Allura&family=Cinzel+Decorative:wght@700&family=Cinzel:wght@400;700&family=Montserrat:wght@300;400&family=Rajdhani:wght@700&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-dark-gold: #deb16c;
  --color-light-gold: #eccf81;
  --color-dark-silver: #606167;
  --color-light-silver: #c6c9d0;
  --container-width: 1100px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.page-link {
  text-decoration: underline;
}

.text-white {
  color: #ffffff;
  color: var(--color-white);
}

.text-light-gold {
  color: #eccf81;
  color: var(--color-light-gold);
}

.text-medium-gold {
  color: #ffd771;
}

.text-dark-gold {
  color: #deb16c;
  color: var(--color-dark-gold);
}

.text-light-silver {
  color: #c6c9d0;
  color: var(--color-light-silver);
}

.text-dark-silver {
  color: #606167;
  color: var(--color-dark-silver);
}

.rajdhani-bold-12 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 12px;
  line-height: 2.75rem;
  letter-spacing: 1px;
}

.rajdhani-bold-18 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.125rem;
  line-height: 2.75rem;
  letter-spacing: 1px;
}

.rajdhani-bold-24 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 1.5rem;
  letter-spacing: 2px;
}

.allura-regular-28 {
  font-family: 'Allura', cursive;
  font-size: 28px;
}

.allura-regular-36 {
  font-family: 'Allura', cursive;
  font-size: 2.25rem;
}

.alfa-slab-one-10 {
  font-family: 'Alfa Slab One', cursive;
  font-size: 10px;
  letter-spacing: 3px;
}

.alfa-slab-one-18 {
  font-family: 'Alfa Slab One', cursive;
  font-size: 18px;
  letter-spacing: 3.5px;
}

.cinzel-regular-24 {
  font-family: 'Cinzel', serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-30 {
  font-family: 'Cinzel', serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-36 {
  font-family: 'Cinzel', serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 31px;
}

.cinzel-regular-48 {
  font-family: 'Cinzel', serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 64px;
}

.cinzel-regular-56 {
  font-family: 'Cinzel', serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
}

.cinzel-bold-36 {
  font-family: 'Cinzel', serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 64px;
}

.cinzel-bold-56 {
  font-family: 'Cinzel', serif;
  font-size: 56px;
  font-weight: bold;
  line-height: 64px;
}

.cinzel-decorative-bold-18 {
  font-family: 'Cinzel Decorative', cursive;
  font-size: 18px;
  font-weight: bold;
}

.montserrat-light-12 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.montserrat-light-14 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
}

.montserrat-regular-14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 28px;
}

.nav-link {
  color: #ffffff;
  color: var(--color-white);
  text-decoration: none;
}

.container-1100 {
  width: 1100px;
  width: var(--container-width);
}

.pt-84 {
  padding-top: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

.ptb-84 {
  padding: 84px 0;
}

.ptb-168 {
  padding: 168px 0;
}

.ml-16 {
  margin-left: 16px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mtb-64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.text-center {
  text-align: center;
}

.Navbar {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.Navbar-container {
  width: var(--container-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Navbar-left,
.Navbar-right {
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.Navbar-hamburger {
  color: var(--color-dark-gold);
}

.Navbar-x {
  color: var(--color-dark-silver);
}

@media screen and (max-width: 768px) {
  .Navbar {
    justify-content: flex-start;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 20;
  }
}

@media screen and (max-width: 1100px) {
  .Navbar-container {
    width: 900px;
  }
}

.MobileMenu {
  width: 100vw;
  height: 100vh;
  background: var(--color-light-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
}

.MobileMenu-container {
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.Footer {
  background: var(--color-dark-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Footer-head div {
  width: 1100px;
  display: flex;
  position: relative;
}

.Footer-head div input[type='email'] {
  width: 100%;
  height: 4.1rem;
  padding-left: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
}

.Footer-head div input[type='email']:focus,
.Footer-head div button {
  outline: none;
}

.Footer-head div button {
  width: 350px;
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-light-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
  position: absolute;
  right: 0;
  font-family: 'Alfa Slab One', cursive;
  font-size: 18px;
  letter-spacing: 3.5px;
  transition: 0.2s;
}

.Footer-head div button:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.Footer-head div div {
  position: absolute;
  top: -3rem;
  left: 2rem;
  color: white !important;
}

.Footer-body {
  width: 1100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Footer-body-left {
  width: 50%;
}

.Footer-body-center {
  width: 42.5%;
}

.Footer-body-right {
  width: 17.5%;
}

.Footer-foot {
  width: 1100px;
  border-top: 1px solid var(--color-light-silver);
  text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
  .Footer-head div {
    width: 900px;
  }

  .Footer-body {
    width: 900px;
  }

  .Footer-foot {
    width: 100%;
  }
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .Footer {
    display: block;
  }

  .Footer-head div {
    width: 100%;
    padding: 0 2rem;
    flex-direction: column;
  }

  .Footer-head div input[type='email'] {
    font-size: 1rem;
  }

  .Footer-head div button {
    width: 100%;
    margin-top: 1rem;
    position: relative;
  }

  .Footer-body {
    width: 77%;
    margin-left: 2rem;
    flex-direction: column;
  }

  .Footer-body-left {
    width: 100%;
  }

  .Footer-body-center {
    width: 100%;
  }

  .Footer-body-right {
    width: 100%;
  }

  .Footer-foot {
    width: 100%;
    border-top: none;
    text-align: center;
  }
}

.Hero {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.Hero .overlay {
  content: '';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.Hero-logo-container {
  height: 332px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Hero-logo {
  z-index: 11;
}

.Hero-text {
  width: 557px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Hero-text p {
  text-shadow: 0 4px 4px black;
}

.btn-hero {
  width: 15rem;
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-light-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
}

.btn-arrow {
  width: 1.4rem;
  display: inline-block;
  margin-top: 2.93px;
  filter: drop-shadow(1px 1px 0 black);
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .Hero-text {
    width: 100%;
  }

  .Hero-logo-container {
    height: 200px;
  }
}

@media screen and (max-width: 320px) {
  .Hero-text {
    width: 100%;
    margin-top: 1rem;
  }

  .cursive-text {
    line-height: 3rem !important;
  }

  .Hero-logo-container {
    height: 100px;
  }

  .btn-hero {
    width: 10rem;
    padding: 0.75rem;
    border: 1.5px solid var(--color-light-gold);
  }

  .small {
    line-height: 1.25rem;
  }
}

.SubHero {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SubHero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.SubHero-left {
  width: 55%;
  height: 19rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.SubHero-left-header {
  margin-top: -2rem;
  display: flex;
  align-items: center;
}

.SubHero-left-icon {
  width: 105px;
  margin-left: -0.75rem;
}

.SubHero-left-title {
  margin-left: 1rem;
}

.SubHero-left-img {
  width: 129px;
  height: 129px;
}

.SubHero-left-text {
  margin-top: -2rem;
  margin-right: 2rem;
}

.SubHero-right {
  width: 45%;
  height: 19rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  gap: 28px;
  justify-items: end;
}

@media screen and (max-width: 1100px) {
  .SubHero {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  .SubHero-left {
    width: 100%;
    align-items: center;
  }

  .SubHero-left-text {
    text-align: justify;
    padding: 0 2.4rem;
    margin-right: 0;
  }
}

@media screen and (max-width: 320px) {
  .SubHero-left-header .small {
    margin-bottom: 2rem;
  }
}

.Reviews-profile-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .Reviews-text-session p {
    text-align: justify;
  }
}

@media screen and (max-width: 320px) {
  .Reviews-text-session p {
    padding: 0 1.8rem;
  }
}

.StoryCard {
  width: 50%;
  /* background: var(--color-light-gold); */
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryCard:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.StoryCard-container {
  padding: 8rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {
  .StoryCard-container {
    padding: 2rem;
  }
}

@media screen and (max-width: 1100px) {
  .StoryCard-container {
    padding: 2rem;
  }
}

.StoryCardPhoto {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryCardPhoto:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.StoryCardMobile {
  position: relative;
}

.StoryCardMobile:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.StoryCardMobile-heading-container {
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  top: 0;
}

/* .Story {
  height: 100vh;
} */

.StorySection {
  width: 100%;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
}

.BottomHero {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.BottomHero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .BottomHero {
    padding: 4rem 0;
  }

  .BottomHero-container p {
    text-align: left;
    line-height: 43px;
    margin: 0 2.4rem;
  }
}

@media screen and (max-width: 1100px) {
  .BottomHero {
    padding: 4rem 1rem;
  }
}


.Attributions {
  background: var(--color-light-gold);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Attributions .Attributions-container img {
  width: 50px;
}

.About {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-gold);
  display: flex;
  align-items: center;
  justify-content: center;
}

.About-container {
  width: 900px;
  max-height: 533px;
  background: white;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  display: flex;
}

.About-container p {
  padding: 4rem;
}

.About-photo {
  width: 400px;
}

@media screen and (min-width: 414px) and (max-width: 768px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}

@media screen and (min-width: 321px) and (max-width: 414px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}

.Book {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-silver);
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.Book-container {
  width: 905px;
  height: 75%;
  margin-top: 12rem;
}

.calendly-inline-widget {
  height: 700px !important;
  margin-top: 2rem !important;
}

.price-container {
  width: 680px;
  background: white;
  border-radius: 5px;
  padding: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.price-container hr {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.price-table-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
  /* .Book {
    background: white;
  } */

  .Book-container {
    width: 50%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); */
    margin-top: 5rem;
  }

  .calendly-inline-widget {
    height: 500px !important;
    margin-bottom: 2rem !important;
  }

  .price-container {
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  /* .Book {
    background: white;
  } */

  .Book-container {
    width: 90%;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25); */
  }

  .calendly-inline-widget {
    height: 500px !important;
    margin-bottom: 2rem !important;
  }

  .price-container {
    width: 100%;
  }
}

.Contact {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-gold);
}

.Contact-container {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact-top {
  width: 100%;
  margin-top: 10rem;
  text-align: center;
}

.Contact-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact-left {
  width: 50%;
}

.Contact-right {
  width: 50%;
  height: 100%;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.Contact-left form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.Contact-left form input,
.Contact-left form textarea,
.Contact-left form button {
  width: 80%;
  margin-top: 2rem;
}

.Contact-left form textarea {
  resize: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.Contact-left form input:focus,
.Contact-left form textarea:focus {
  outline: none;
}

.Contact-left form input {
  height: 4.1rem;
  padding-left: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.Contact-left form textarea {
  height: 14.1rem;
  padding: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
}

.Contact-left form button {
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-dark-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
}

.Contact-left form button:hover {
  cursor: pointer;
}

.Contact-right-top h3,
.Contact-right-bottom h3 {
  margin-bottom: 2rem;
}

.Contact-right-bottom h3 {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .Contact-top {
    margin-top: 5rem;
  }

  .Contact-bottom {
    flex-direction: column;
  }

  .Contact-left {
    width: 100%;
  }

  .Contact-right {
    width: 100%;
    margin-left: 4rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .Contact-left form {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 414px) {
  .Contact-right-top h3,
  .Contact-right-bottom h3 {
    font-size: 1rem;
  }

  .Contact-right-top a,
  .Contact-right-bottom a {
    font-size: 0.85rem;
  }
}

.Payment {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Blog {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Blog-header {
  margin-top: 160px;
}

.Blog-body {
  width: 100%;
  height: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Blog-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4rem;
  gap: 4rem;
}

.Blog-card {
  width: 400px;
  height: 432px;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.Blog-image {
  position: relative;
  border-radius: 15px 15px 0 0;
}

.Blog-image:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-top: 8px solid var(--color-dark-gold);
  border-radius: 15px 15px 0 0;
}

.Blog-content div:first-child {
  height: 80px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
}

.Blog-card-container span h3 {
  margin: 2rem;
  color: black;
  text-align: center;
}

.Blog-content div {
  margin: 2rem;
  margin-top: 0;
  color: var(--color-dark-silver);
}

.Blog-link {
  margin-right: 3rem;
  text-align: right;
  color: var(--color-dark-gold);
  text-decoration: underline;
  font-size: 0.75rem;
}

@media screen and (max-width: 1439px) {
  .Blog-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .Blog {
    padding: 0 2rem;
  }

  .Blog-header {
    margin-top: 5rem;
  }

  .Blog-card {
    width: 90vw;
    height: 432px;
    background: white;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }

  .Blog-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.SinglePost {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.SinglePost-header {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SinglePost-image {
  width: 768px;
  height: 600px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.SinglePost-author {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SinglePost-author-left {
  display: flex;
  align-items: center;
}

.SinglePost-author-right {
  display: flex;
  align-items: center;
}

.SinglePost-body {
  max-width: 768px;
}

.SinglePost-body * {
  margin-top: 64px;
}

.SinglePost-body p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
}

.SinglePost-body img {
  width: 100%;
}

@media screen and (min-width: 414px) and (max-width: 768px) {
  .SinglePost-header {
    margin-top: 5rem;
  }

  .SinglePost-image {
    width: 100%;
  }

  .SinglePost-body {
    width: 90vw;
  }
}

@media screen and (max-width: 414px) {
  .SinglePost-header {
    margin-top: 5rem;
  }

  .SinglePost-header h1 {
    font-size: 2rem;
  }

  .SinglePost-image {
    width: 100%;
  }

  .SinglePost-body {
    width: 90vw;
  }
}

.App {
  position: relative;
}

