.Navbar {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.Navbar-container {
  width: var(--container-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Navbar-left,
.Navbar-right {
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.Navbar-hamburger {
  color: var(--color-dark-gold);
}

.Navbar-x {
  color: var(--color-dark-silver);
}

@media screen and (max-width: 768px) {
  .Navbar {
    justify-content: flex-start;
    position: fixed;
    top: 1rem;
    left: 1rem;
    z-index: 20;
  }
}

@media screen and (max-width: 1100px) {
  .Navbar-container {
    width: 900px;
  }
}
