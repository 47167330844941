.Hero {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.Hero .overlay {
  content: '';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.Hero-logo-container {
  height: 332px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Hero-logo {
  z-index: 11;
}

.Hero-text {
  width: 557px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Hero-text p {
  text-shadow: 0 4px 4px black;
}

.btn-hero {
  width: 15rem;
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-light-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
}

.btn-arrow {
  width: 1.4rem;
  display: inline-block;
  margin-top: 2.93px;
  filter: drop-shadow(1px 1px 0 black);
}

@media screen and (min-width: 321px) and (max-width: 768px) {
  .Hero-text {
    width: 100%;
  }

  .Hero-logo-container {
    height: 200px;
  }
}

@media screen and (max-width: 320px) {
  .Hero-text {
    width: 100%;
    margin-top: 1rem;
  }

  .cursive-text {
    line-height: 3rem !important;
  }

  .Hero-logo-container {
    height: 100px;
  }

  .btn-hero {
    width: 10rem;
    padding: 0.75rem;
    border: 1.5px solid var(--color-light-gold);
  }

  .small {
    line-height: 1.25rem;
  }
}
