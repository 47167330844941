.MobileMenu {
  width: 100vw;
  height: 100vh;
  background: var(--color-light-gold);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
}

.MobileMenu-container {
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
