.Contact {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-gold);
}

.Contact-container {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Contact-top {
  width: 100%;
  margin-top: 10rem;
  text-align: center;
}

.Contact-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact-left {
  width: 50%;
}

.Contact-right {
  width: 50%;
  height: 100%;
  margin-left: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.Contact-left form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.Contact-left form input,
.Contact-left form textarea,
.Contact-left form button {
  width: 80%;
  margin-top: 2rem;
}

.Contact-left form textarea {
  resize: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.Contact-left form input:focus,
.Contact-left form textarea:focus {
  outline: none;
}

.Contact-left form input {
  height: 4.1rem;
  padding-left: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

.Contact-left form textarea {
  height: 14.1rem;
  padding: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
}

.Contact-left form button {
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-dark-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
}

.Contact-left form button:hover {
  cursor: pointer;
}

.Contact-right-top h3,
.Contact-right-bottom h3 {
  margin-bottom: 2rem;
}

.Contact-right-bottom h3 {
  margin-top: 2rem;
}

@media screen and (max-width: 768px) {
  .Contact-top {
    margin-top: 5rem;
  }

  .Contact-bottom {
    flex-direction: column;
  }

  .Contact-left {
    width: 100%;
  }

  .Contact-right {
    width: 100%;
    margin-left: 4rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .Contact-left form {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 414px) {
  .Contact-right-top h3,
  .Contact-right-bottom h3 {
    font-size: 1rem;
  }

  .Contact-right-top a,
  .Contact-right-bottom a {
    font-size: 0.85rem;
  }
}
