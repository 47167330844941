.SinglePost {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.SinglePost-header {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SinglePost-image {
  width: 768px;
  height: 600px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.SinglePost-author {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SinglePost-author-left {
  display: flex;
  align-items: center;
}

.SinglePost-author-right {
  display: flex;
  align-items: center;
}

.SinglePost-body {
  max-width: 768px;
}

.SinglePost-body * {
  margin-top: 64px;
}

.SinglePost-body p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 28px;
  text-align: justify;
}

.SinglePost-body img {
  width: 100%;
}

@media screen and (min-width: 414px) and (max-width: 768px) {
  .SinglePost-header {
    margin-top: 5rem;
  }

  .SinglePost-image {
    width: 100%;
  }

  .SinglePost-body {
    width: 90vw;
  }
}

@media screen and (max-width: 414px) {
  .SinglePost-header {
    margin-top: 5rem;
  }

  .SinglePost-header h1 {
    font-size: 2rem;
  }

  .SinglePost-image {
    width: 100%;
  }

  .SinglePost-body {
    width: 90vw;
  }
}
