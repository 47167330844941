.About {
  width: 100vw;
  min-height: 100vh;
  background: var(--color-light-gold);
  display: flex;
  align-items: center;
  justify-content: center;
}

.About-container {
  width: 900px;
  max-height: 533px;
  background: white;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  display: flex;
}

.About-container p {
  padding: 4rem;
}

.About-photo {
  width: 400px;
}

@media screen and (min-width: 414px) and (max-width: 768px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}

@media screen and (min-width: 321px) and (max-width: 414px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .About {
    background: white;
    align-items: flex-end;
  }

  .About-container {
    width: 100%;
    max-height: 100%;
    background: white;
    padding: 1rem;
    box-shadow: none;
    flex-direction: column;
  }

  .About-container p {
    padding: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;
    text-align: justify;
  }

  .About-photo {
    width: 100%;
  }
}
