.Reviews-profile-image {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .Reviews-text-session p {
    text-align: justify;
  }
}

@media screen and (max-width: 320px) {
  .Reviews-text-session p {
    padding: 0 1.8rem;
  }
}
