.Footer {
  background: var(--color-dark-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.Footer-head div {
  width: 1100px;
  display: flex;
  position: relative;
}

.Footer-head div input[type='email'] {
  width: 100%;
  height: 4.1rem;
  padding-left: 2rem;
  border-radius: 50px;
  border: none;
  border-top: 4px solid var(--color-light-silver);
  border-bottom: 4px solid var(--color-black);
  font-size: 1.5rem;
  color: var(--color-dark-silver);
}

.Footer-head div input[type='email']:focus,
.Footer-head div button {
  outline: none;
}

.Footer-head div button {
  width: 350px;
  padding: 1rem;
  background: linear-gradient(
    105deg,
    var(--color-dark-silver),
    var(--color-light-silver)
  );
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-light-gold);
  border-radius: 50px;
  text-shadow: 1px 1px 0 black;
  box-shadow: 0 4px 0 black;
  position: absolute;
  right: 0;
  font-family: 'Alfa Slab One', cursive;
  font-size: 18px;
  letter-spacing: 3.5px;
  transition: 0.2s;
}

.Footer-head div button:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.Footer-head div div {
  position: absolute;
  top: -3rem;
  left: 2rem;
  color: white !important;
}

.Footer-body {
  width: 1100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Footer-body-left {
  width: 50%;
}

.Footer-body-center {
  width: 42.5%;
}

.Footer-body-right {
  width: 17.5%;
}

.Footer-foot {
  width: 1100px;
  border-top: 1px solid var(--color-light-silver);
  text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 1100px) {
  .Footer-head div {
    width: 900px;
  }

  .Footer-body {
    width: 900px;
  }

  .Footer-foot {
    width: 100%;
  }
}

/* MOBILE */
@media screen and (max-width: 768px) {
  .Footer {
    display: block;
  }

  .Footer-head div {
    width: 100%;
    padding: 0 2rem;
    flex-direction: column;
  }

  .Footer-head div input[type='email'] {
    font-size: 1rem;
  }

  .Footer-head div button {
    width: 100%;
    margin-top: 1rem;
    position: relative;
  }

  .Footer-body {
    width: 77%;
    margin-left: 2rem;
    flex-direction: column;
  }

  .Footer-body-left {
    width: 100%;
  }

  .Footer-body-center {
    width: 100%;
  }

  .Footer-body-right {
    width: 100%;
  }

  .Footer-foot {
    width: 100%;
    border-top: none;
    text-align: center;
  }
}
